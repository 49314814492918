import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Form.css';

function Form({ onClose }) {
  const [productCode, setProductCode] = useState('');
  const [aliExpressLinks, setAliExpressLinks] = useState([{ text: '', url: '', thumbnailSrc: '', additionalLinks: [] }]);
  const [password, setPassword] = useState('');
  const [video, setVideo] = useState(null);
  const [thumbnailFiles, setThumbnailFiles] = useState({});
  const [category, setCategory] = useState('');
  const handleCategoryChange = (e) => setCategory(e.target.value);

  const handleProductCodeChange = (e) => setProductCode(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleVideoChange = (e) => setVideo(e.target.files[0]);
  const handleThumbnailChange = (index, e) => {
    const file = e.target.files[0];
    console.log('Selected thumbnail file:', file); // Debugging
    setThumbnailFiles({ ...thumbnailFiles, [index]: file });
  };

  const handleLinkChange = async (index, e) => {
    const newLinks = [...aliExpressLinks];
    newLinks[index].url = e.target.value;

    if (e.target.value) {
      try {
        const response = await axios.post('https://polskie-amalinki.pl/api/thumbnail', { url: e.target.value });
        newLinks[index].thumbnailSrc = response.data.thumbnailSrc || '';
        if (!response.data.thumbnailSrc) {
          console.error('Nie udało się pobrać miniatury. Spróbuj ponownie.');
        }
      } catch (error) {
        console.error('Wystąpił błąd podczas pobierania miniatury:', error);
      }
    }

    setAliExpressLinks(newLinks);
  };

  const handleNameChange = (index, e) => {
    const newLinks = [...aliExpressLinks];
    newLinks[index].text = e.target.value;
    setAliExpressLinks(newLinks);
  };

  const handleAddLink = () => {
    setAliExpressLinks([...aliExpressLinks, { text: '', url: '', thumbnailSrc: '', additionalLinks: [] }]);
  };

  const handleAdditionalLinkChange = async (linkIndex, additionalIndex, e) => {
    const newLinks = [...aliExpressLinks];
    newLinks[linkIndex].additionalLinks[additionalIndex][e.target.name] = e.target.value;

    if (e.target.name === 'url') {
      try {
        const response = await axios.post('https://polskie-amalinki.pl/api/delivery-time', { url: e.target.value });
        newLinks[linkIndex].additionalLinks[additionalIndex].deliveryTime = response.data.deliveryTime;
      } catch (error) {
        console.error('Wystąpił błąd podczas pobierania czasu dostawy:', error);
        newLinks[linkIndex].additionalLinks[additionalIndex].deliveryTime = 'Czas dostawy niedostępny';
      }
    }

    setAliExpressLinks(newLinks);
  };

  const handleAddAdditionalLink = (index) => {
    const newLinks = [...aliExpressLinks];
    newLinks[index].additionalLinks.push({ text: '', url: '', deliveryTime: '' });
    setAliExpressLinks(newLinks);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Przygotowanie danych formularza
    const formData = new FormData();
    formData.append('productCode', productCode);
    formData.append('aliExpressLinks', JSON.stringify(aliExpressLinks));
    formData.append('password', password);
    formData.append('category', category); // Dodaj kategorię
    if (video) {
      formData.append('video', video);
    }
    
  
    // Przesyłanie miniaturek
    const thumbnailPromises = Object.entries(thumbnailFiles).map(async ([index, file]) => {
      const thumbnailData = new FormData();
      thumbnailData.append('thumbnail', file);
      try {
        const response = await axios.post('https://polskie-amalinki.pl/api/uploadThumbnail', thumbnailData);
        const newLinks = [...aliExpressLinks];
        newLinks[index].thumbnailSrc = response.data.thumbnailPathProduct;
        setAliExpressLinks(newLinks);
      } catch (error) {
        console.error('Wystąpił błąd podczas przesyłania miniatury:', error); // Logowanie błędów miniatur
      }
    });
  
    // Oczekiwanie na przesłanie wszystkich miniaturek
    await Promise.all(thumbnailPromises);
  
    // Przesyłanie danych produktu
    try {
      const response = await axios.post('https://polskie-amalinki.pl/api/products', formData);
      if (response.status === 200) {
        console.log('Dane zostały przesłane pomyślnie');
        onClose();
      } else {
        console.error('Błąd podczas przesyłania danych', response); // Logowanie błędu przesyłania z odpowiedzią serwera
      }
    } catch (error) {
      console.error('Wystąpił błąd:', error); // Logowanie błędów wysyłki
    }
  };
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://polskie-amalinki.pl/api/initialData');
        const links = response.data.aliExpressLinks || []; // Sprawdź, czy dane istnieją
        setProductCode(response.data.productCode);
        setAliExpressLinks(links);
        setPassword(response.data.password);
      } catch (error) {
        console.error('Wystąpił błąd podczas pobierania danych:', error);
      }
    };
  
    fetchData();
  }, []);
  

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <div>
          <label>Filmik produktu:</label>
          <input type="file" accept="video/*" onChange={handleVideoChange} />
        </div>
        <div>
          <label>Kod produktu:</label>
          <input type="text" value={productCode} onChange={handleProductCodeChange} />
        </div>
        <div>
          <label>Kategoria:</label>
          <select value={category} onChange={handleCategoryChange}>
            <option value="">Wybierz kategorię</option>
            <option value="home">Dom</option>
            <option value="pets">Zwierzęta</option>
            <option value="events">Święta</option>
            <option value="kids">Dzieci</option>
            <option value="gifts">Prezenty</option>
            <option value="finds">Znaleziska</option>
            <option value="seasons">Pory Roku</option>
            <option value="best">Topka</option>
          </select>
        </div>
        {aliExpressLinks?.map((linkObj, index) => (
          <div key={index}>
            <label>Link do AliExpress, Amazon lub Temu:</label>
            <input type="text" value={linkObj.url} onChange={(e) => handleLinkChange(index, e)} />
            <label>Nazwa produktu:</label>
            <input type="text" value={linkObj.text} onChange={(e) => handleNameChange(index, e)} />
            <label>Miniaturka produktu:</label>
            <input type="file" accept="image/*" className="kurwaImage" onChange={(e) => handleThumbnailChange(index, e)} />
            <div className="link-container">
              {linkObj.thumbnailSrc && (
                <img src={linkObj.thumbnailSrc} alt="Product Thumbnail" className="thumbnail-image" />
              )}
              <div className="link-text">{linkObj.text}</div>
            </div>
            {linkObj.additionalLinks.map((additionalLink, additionalIndex) => (
              <div key={additionalIndex}>
                <label>Dodatkowy link:</label>
                <input
                  type="text"
                  name="url"
                  value={additionalLink.url}
                  onChange={(e) => handleAdditionalLinkChange(index, additionalIndex, e)}
                />
                <label>Czas dostawy:</label>
                <input
                  type="text"
                  name="deliveryTime"
                  value={additionalLink.deliveryTime}
                  readOnly
                />
              </div>
            ))}
            <button type="button" onClick={() => handleAddAdditionalLink(index)}>
              Dodaj dodatkowy link
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddLink}>
          Dodaj kolejny produkt
        </button>
        <div>
          <label>Hasło:</label>
          <input type="password" value={password} onChange={handlePasswordChange} />
        </div>
        <button type="submit">Wyślij</button>
        <button type="button" onClick={onClose}>Zamknij</button>
      </form>
    </div>
  );
  
}

export default Form;
